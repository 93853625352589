html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
}

.main-container {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.page-container {
    display: flex;
    justify-content: center;
    position: relative;
    min-height: 100vh;
}

.page {
    max-width: 1000px;
    margin: 8px;
}

@media screen and (max-width: 700px) {
    .page {
        width: 100%;
        padding: 0px;
    }
    .github {
        padding-right: 0px;
    }
}

.info-header {
    font-weight: 700;
    font-size: 20px;
    text-decoration: none;
    color: black;
}

.info-detail {
    font-weight: 300;
    font-size: 15px;
    line-height: 28px;
    color: #989898;
}

.links-container {
    overflow: hidden;
    display: flex;
    justify-content: center;
    white-space: nowrap;
    align-items: center;
    flex-direction: row;
    height: 100px;
}

.links-container a {
    color: rgba(0, 128, 96, 1);
    text-align: center;
    text-decoration: none;
    line-height: 1;
    font-size: 20px;
    font-weight: 300;
    padding: 12px;
    transition: color 0.2s;
}

.links-container a:hover,
.links-container a:active {
    color: #fcaf38;
    transition: color 0.2s;
}

.job-container a {
    text-decoration: none !important;
}

.job-container {
    padding-bottom: 50px;
    max-width: 900px;
}

.job-description {
    font-style: 'light';
    font-size: 15px;
    margin: 0;
    line-height: 25px;
    color: #989898;
}

.job-title {
    font-weight: 300;
    font-size: 20px;
    color: rgba(0, 128, 96, 1);
    margin: 0;
    transition: color 0.2s;
}

.job-title:hover,
.job-title:active {
    color: #fcaf38;
    transition: color 0.2s;
}

.job-title span {
    font-weight: 300;
    font-size: 25px;
    color: black;
}

.job-company {
    font-size: 20px !important;
}

.project-text-title {
    font-weight: 300;
    font-size: 20px;
}

.project-text-description {
    font-weight: 300;
    font-size: 15px;
}

.project-text-date {
    font-weight: 300;
    font-size: 15px;
}

.project-text-mobile {
    position: relative;
    bottom: -28px;
    /* bottom: -15px; */
    font-weight: 300;
    font-size: 20px;
    line-height: 0;
    display: none;
    padding: 0;
    margin-top: 10;
    position: relative;
}

.dev-design {
    position: relative;
    top: -3px;
    font-weight: 300;
    font-size: 20px;
    line-height: 0;
    padding: 0;
    margin-top: 10;
    position: relative;
}

.project-description-mobile {
    font-weight: 300;
    font-size: 15px;
    color: #989898;
    line-height: 28px;
    display: none;
    padding: 0;
    position: relative;
    bottom: -14px;
    margin-bottom: 10px;
}

@media screen and (max-width: 700px) {
    .project-text-mobile {
        display: inline;
    }
    .project-description-mobile {
        display: block;
    }
    .project-image-text {
        display: none;
    }
}

.project-description {
    font-style: 'light';
    font-size: 15px;
    margin: 0;
}

.project-image-container {
    position: relative;
    color: white;
    font-size: 20px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
}

.project-image-text {
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%;
    opacity: 0;
    transition: opacity 0.1s;
    font-size: 1.5rem;
    cursor: pointer;
}

.project-image-text:hover {
    opacity: 1;
}

.project-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: filter 0.2s;
    cursor: pointer;
}

.project-image-container:hover .project-image {
    filter: brightness(50%);
    transition: opacity 0.2s;
}

.project-image-container:hover .project-image-text {
    opacity: 1;
    transition: opacity 0.2s;
}

.parent-node {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
}

.center-text {
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-link {
    color: rgba(0, 128, 96, 1);
    text-align: center;
    text-decoration: none;
    font-size: 20px;
    font-weight: 300;
    padding: 20px;
    transition: color 0.2s;
}

.page-link:hover {
    color: #fcaf38;
    transition: color 0.2s;
}

.image-link {
    display: none;
}

.drop-up {
    width: 100%;
    display: block;
}

.hidden {
    display: none;
}

.see-more {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
}

.center-drawer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* height: 100vh; */
}

.skills-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    padding: 0;
    margin: 0;
}

.skills-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 5px;
    width: 300px;
    height: 200px;
    margin: 10px;
}

.tech-logo-container-wide {
    padding: 0;
    margin: 0px;
}

.tech-logo {
    height: 20%;
    object-fit: cover;
}

.skill-name {
    font-weight: 700;
    color: rgba(0, 128, 96, 1);
    font-size: 20px;
    margin: 0px;
    padding-top: 10px;
}

.skill-text {
    color: #989898;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    padding-top: 5px;
    margin: 0;
}

.page-title {
    margin: 0;
    padding: 0;
    border-bottom: 5px solid #fcaf38;
}

.codebox-container {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #f2f2f2;
}

.codebox-container img {
    position: relative;
    left: 20px;
    object-fit: contain;
    height: 100%;
}

.text-spacer {
    height: 20px;
}

.footer-container {
    flex: 1;
    display: flex;
    position: relative;
    padding: 0;
    margin: 0;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: #989898;
    background-color: black;
    overflow: hidden;
    left: 0;
    bottom: 0;
    right: 0;
}

.icon-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    padding-top: 50px;
    max-width: 160px;
}

.footer-text {
    padding-bottom: 0;
    font-size: 15px;
    font-weight: 300;
}

.footer-image {
    height: 60%;
    width: 60%;
}

.footer-link {
    text-align: center;
}

.footer-email {
    color: white;
    font-size: 15px;
    font-weight: 300;
    padding-bottom: 50px;
    line-height: 0;
}

.skills-title {
    position: relative;
    top: -10px;
    font-size: 20px;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 25px;
}

.text-center {
    text-align: center;
}

.about-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
