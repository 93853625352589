.title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    flex-direction: column;
    width: 100%;
}

.project-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 400px;
    text-align: center;
}

.project-title {
    border-bottom: 5px solid rgba(0, 128, 96, 1);
    margin: 15px;
}

.project-description-brief {
    max-width: 460px;
    color: #989898;
    font-size: 20;
    font-weight: 400;
    text-align: center;
    line-height: 25px;
    margin: 0px;
}

.project-description-link {
    text-decoration: none;
    font-size: 20;
    color: rgba(0, 128, 96, 1);
    font-weight: 700;
    margin: 10px;
    transition: color 0.2s;
}

.project-description-link:hover,
.project-description-link:active {
    color: #fcaf38;
    transition: color 0.2s;
}

.project-text-link {
    text-decoration: none;
    color: rgba(0, 128, 96, 1);
    font-weight: 700;
    transition: color 0.2s;
}

.project-text-link:hover,
.project-text-link:active {
    color: #fcaf38;
    transition: color 0.2s;
}

.static-project-image-container {
    position: relative;
    margin-top: 40px;
    margin-bottom: 40px;
}

.static-project-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.project-about {
    font-weight: 700;
    font-size: 20px;
}

.project-description {
    /* max-width: 500px; */
    color: #989898;
    line-height: 25px;
}

.project-tech {
    color: #989898;
    line-height: 25px;
}

.project-link-list-container {
    color: #989898;
}

.project-link-list {
    color: rgba(0, 128, 96, 1);
    text-decoration: none;
    font-weight: 700;
    line-height: 25px;
    transition: color 0.2s;
}

.project-link-list:hover,
.project-link-list:active {
    color: #fcaf38;
    transition: color 0.2s;
}

.static-project-images {
    padding-bottom: 0px;
    position: relative;
    top: -50px;
}

.static-project-spacing {
    margin-top: 50px;
}

.project-page-title {
    margin-bottom: 8px;
    border-bottom: 5px solid #fcaf38;
}

.static-links-container {
    overflow: hidden;
    display: flex;
    justify-content: center;
    white-space: nowrap;
}

.static-links-container a {
    color: #989898;
    text-align: center;
    text-decoration: none;
    line-height: 1;
    font-size: 20px;
    font-weight: 300;
    padding: 12px;
    transition: color 0.2s;
}

.static-links-container a:hover,
.static-links-container a:active {
    color: #fcaf38;
    transition: color 0.2s;
}

.info-header-spacing {
    position: relative;
    top: -105px;
}

.project-info-header {
    font-weight: 300;
    font-size: 20px;
    display: relative;
    bottom: -30px;
    text-decoration: none;
    color: black;
}

@media screen and (max-width: 700px) {
    .project-info-header {
        font-weight: 300;
        text-align: center;
        font-size: 20px;
        display: relative;
        bottom: -30px;
        text-decoration: none;
        color: #008060;
    }
}

.spacer {
    height: 50px;
}

.project-sub-about {
    font-weight: 500;
    font-size: 18px;
}

.project-em {
    color: rgba(0, 128, 96, 1);
}

.project-bold {
    color: black;
    font-weight: 700;
}

.green {
    color: rgba(0, 128, 96, 1);
    text-decoration: none;
}

.gray {
    color: #989898;
}

.bold {
    font-weight: 700;
}

.black {
    color: black;
}

.experience-container {
    position: relative;
}

.link-component-icons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.link-component-image {
    height: 40px;
    width: 40px;
    padding: 30px;
}

.project-description-links {
    display: flex;
    justify-content: center;
    align-items: center;
}
