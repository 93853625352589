.full-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
}

.full-page {
    max-width: 1000px;
    text-align: center;
}

.contact-overview {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.circular-portrait {
    position: relative;
    width: 15rem;
    height: 15rem;
    overflow: hidden;
    border-radius: 50%;
}

.circular-portrait img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    margin: 0px;
    padding: 0px;
}

.contact-email {
    font-size: 15px;
    color: #989898;
    /* position: relative; */
    /* top: -35px; */
}

.in-touch {
    padding: 12px;
    padding-top: 20px;
    line-height: 0;
}

.contact-links-container {
    overflow: hidden;
    display: flex;
    justify-content: center;
    white-space: nowrap;
    align-items: center;
    flex-direction: row;
}

.contact-links-container a {
    color: rgba(0, 128, 96, 1);
    text-align: center;
    text-decoration: none;
    line-height: 1;
    font-size: 20px;
    font-weight: 300;
    padding: 12px;
    margin: 0px;
    transition: color 0.2s;
}

.contact-links-container a:hover,
.contact-links-container a:active {
    color: #fcaf38;
    transition: color 0.2s;
}
