.header-container {
    height: 70vh;
    position: relative;
}

.header {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    font-size: 80px;
    font-weight: 100;
}
