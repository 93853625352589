.work-title-description {
    margin-top: 10px;
    padding: 0;
    color: #989898;
    text-align: center;
    line-height: 25px;
    font-size: 20px;
    font-weight: 300;
    max-width: 500px;
}
