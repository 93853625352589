.navbar {
    display: flex;
    position: fixed;
    width: 100%;
    background-color: white;
    z-index: 10;
    top: 0;
    left: 0;
    transition: box-shadow 0.5s;
    overflow: none;
}

.floating {
    box-shadow: 0px 1px 10px #999;
    transition: box-shadow 0.5s;
}

.navlinks {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item {
    margin-left: 2%;
}

.navlinks a {
    float: left;
    display: block;
    color: #989898;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    font-weight: 700;
    padding: 18px;
    transition: color 0.2s;
    margin: 0;
}

.navlinks a:hover {
    color: rgba(0, 128, 96, 1);
    transition: color 0.2s;
}

.navlinks a.active {
    color: rgba(0, 128, 96, 1);
}

.drawer-links {
    /* color: rgba(0, 128, 96, 1); */
    color: #989898;
    text-align: center;
    text-decoration: none;
    font-size: 18px !important;
    font-weight: 700;
    padding: 15px;
    margin: 0;
    width: 100%;
}

.drawer-links:hover {
    color: rgba(0, 128, 96, 1);
}

.drawer-links.active {
    color: rgba(0, 128, 96, 1);
}

.drawer-links:active {
    color: rgba(0, 128, 96, 1);
}

.navlinks img {
    height: 15px;
    width: 15px;
    display: none;
}

.logo {
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.github {
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 20px;
    width: 83px;
}

.github a {
    height: 30px;
    width: 30px;
}

.github-drawer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.burger {
    display: none;
    width: 83px;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
}

.burger img {
    height: 30px;
    width: 30px;
}

.burger img:hover {
    background-image: url('../images/dots.png') no-repeat;
}

.drawer-link {
    font-size: 20px;
}

@media screen and (max-width: 700px) {
    .navlinks a {
        display: none;
    }
    .navlinks.responsive {
        position: relative;
    }
    .navlinks.responsive a.icon {
        position: absolute;
        right: 0;
        top: 0;
    }
    .navlinks.responsive a {
        float: none;
        display: block;
        text-align: left;
    }
    .logo {
        padding-left: 20px;
    }
    .github {
        display: none;
    }
    .burger {
        display: flex;
    }
}
